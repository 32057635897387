<template>
  <CCard class="p-4">
    <CCardBody>
      <h1 class="login-title">{{ $t("Forgot password") }}</h1>
      <p class="text-muted">{{ $t("Enter email assigned to your account") }}</p>
      <CForm @submit.prevent="handleSubmit">
        <label class="login__label mb-20">
          <span>Email</span>
          <div class="login__input">
            <CIcon name="mail" size="custom" />
            <b-form-input
              v-model="email"
              autocomplete="email"
              required
              name="email"
              :placeholder="$t('Email')"
            ></b-form-input>
          </div>
        </label>
        <!-- <CInput
          :placeholder="$t('Email')"
          autocomplete="email"
          v-model="email"
          name="email"
          required
        >
          <template #prepend-content>
            <CIcon name="cil-user"/>
          </template>
        </CInput> -->
        <CButton
          color="primary"
          class="px-4 mt-2 cus-button"
          type="submit"
          data-cy="reset-password-btn"
        >
          {{ $t("Reset password") }}
        </CButton>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { AuthService } from "@/services/auth";

export default {
  name: "ForgotPassword",
  data: function () {
    return {
      email: "",
    };
  },
  methods: {
    async handleSubmit() {
      await AuthService.forgotPassword(this.email);
      alert(this.$t("auth.request_success"));
      this.$router.push({ name: "Management Login" });
    },
  },
};
</script>
